<template>
  <div class="page search-page">
    <BreadcrumbsComponent :title="'Поиск по запросу «' + $route.query.text + '»'" />
    <div class="page__inner">
      <SectionComponent>
        <div class="search-page__inner">
          <span class="search-page__title">
            {{ products.length | plural_word("Найден:", "Найдено:") }} {{ products.length }}
            {{ products.length | plural_word("товар", "товара", "товаров") }}
          </span>
          <div class="search-page__content">
            <div v-for="(product, i) in products" :key="i">
              <ProductCardComponent :data="product" />
            </div>
          </div>
          <div class="search-page__pagination">
            <PaginationComponent
              v-if="Math.ceil(total / first) > 1"
              :page="page"
              :total="Math.ceil(total / first)"
              @change="reload"
            />
          </div>
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";
import PaginationComponent from "components/Pagination.vue";
import SEARCH_PAGE from "@/graphql/pages/SearchPage.graphql";

export default {
  name: "SearchPage",
  components: { PaginationComponent, ProductCardComponent, SectionComponent, BreadcrumbsComponent },
  data() {
    return {
      first: 12,
      page: 1,
    };
  },
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: SEARCH_PAGE,
        variables: {
          first: 12,
          page: 1,
          title: route.query.text || undefined,
        },
      })
      .then(({ data }) => {
        store.state.search_page = data.products_paginate;
      });
  },
  methods: {
    async reload(page = 1) {
      await this.$apolloProvider.defaultClient
        .query({
          query: SEARCH_PAGE,
          variables: {
            page: page,
            first: this.first,
            title: this.$route.query.text || undefined,
          },
        })
        .then(({ data }) => {
          this.$store.state.search_page = data.products_paginate;
          this.page = page;
        });
    },
  },
  computed: {
    products() {
      return this.$store.state.search_page.data;
    },
    total() {
      return this.$store.state.search_page.paginatorInfo.total;
    },
  },
};
</script>

<style lang="stylus">
.search-page {

  &__inner {
    display flex
    flex-direction column
    gap 30px
    +below(492px) {
      gap 15px
    }
  }

  &__title {
    color var(--dark-light)
    +below(492px) {
      font-size 0.875em
      line-height 20px
    }
  }

  &__content {
    display grid
    grid-gap 30px
    grid-template-columns repeat(4, 1fr)
    +below(953px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(722px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(492px) {
      grid-gap 15px
    }
  }

  &__pagination {
    +below(492px) {
      margin-top 15px
    }
  }
}
</style>
